import { ProductModality, TypeOfContract } from "../onboarding/types";

export enum BillingPeriod {
  MONTHLY = "Monthly",
  ANNUAL = "Annual",
}

export enum ProductOnboardingIdentityProvider {
  ADFS = "Adfs",
  AZURE = "Azure",
  PING = "Ping",
  OKTA = "Okta",
}

export type ProductOnboardingUser = {
  firstName: string;
  lastName: string;
  emailAddress: string;
};

export enum ProductOnboardingAuthenticationMethod {
  LWA = "LWA",
  SSO = "SSO",
  APN = "APN",
}

export type ProductOnboardingDetails = {
  organizationName: string;
  learningAdmins: ProductOnboardingUser[];
  emailDomains: string[];
  identityProvider: ProductOnboardingIdentityProvider;
  ssoContact: ProductOnboardingUser;
  authenticationMethod?: ProductOnboardingAuthenticationMethod | null;
};

export type OrderLine = {
  id: string;
  opportunityId: string;
  amount: number;
  createdBy: any;
  status: string;
  learningAccountId?: string;
};

export type AuditMetadata = {
  createdBy: string;
  createdAt: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string;
};

export type ContractingRequestCreator = {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
};

export enum B2BBillingStatus {
  TO_BE_BILLED = "TO_BE_BILLED",
  BILL_IMMEDIATELY = "BILL_IMMEDIATELY",
  BILLED_VIA_CLIPPER_OCB = "BILLED_VIA_CLIPPER_OCB",
  BILLING_IN_PROGRESS = "BILLING_IN_PROGRESS",
  BILLING_FAILED = "BILLING_FAILED",
  BILLED = "BILLED",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  TO_BE_ACCRUED = "TO_BE_ACCRUED",
  ACCRUED = "ACCRUED",
}

export type NMBSBillingDetails = {
  agreementId?: string;
  billInitiatedAt?: string;
  billId?: string;
  billingConsoleUILink?: string;
  billingStatus: B2BBillingStatus;
  billingStatusUpdatedAt: string;
  billingErrorMessage?: string;
  invoiceId?: string;
};

export type OrderItem = {
  opportunityLineItemId: string;
  id: string;
  quantity: number;
  productTitle: string;
  productOnboardingDetails?: ProductOnboardingDetails;
  ssoEnabled: boolean;
  billingPeriod: BillingPeriod;
  unitPrice: number;
  subTotal: number;
  classStartDate: string;
  classEndDate: string;
  contractingRequestCreator: ContractingRequestCreator;
  subscriptionType?: TypeOfContract;
  productModality?: ProductModality;
  metadata?: Array<Record<string, string>>;
  nmbsBillingDetails?: NMBSBillingDetails;
  billingAwsAccountId?: string;
};

export type Order = {
  id: string;
  opportunityId: string;
  status: string;
  items: OrderItem[];
  auditMetadata: AuditMetadata;
  learningAccount?: any;
  orderCanceledTicketId?: string;
  typeOfContract?: string | null;
  billingAwsAccountId?: string;
};
