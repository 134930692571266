import { Box, ColumnLayout, Container, Header, SpaceBetween, Button } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";
import { Order } from "../types";
import { useState } from "react";
import { orderSyncWithSFDCEnabled } from "../../common/featureFlags";

interface OrderProps {
  order: Order;
}

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

export function DetailsTable(props: OrderProps): JSX.Element {
  const intl = useIntl();
  const [showProgress, setShowProgress] = useState(false);
  const isSyncWithSFDCEnabled = orderSyncWithSFDCEnabled();

  const handleAction = async () => {
    try {
      setShowProgress(true);

      // Simulate API call
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          // Filler function to test error and success
          Math.random() > 0.5 ? resolve(null) : reject(new Error());
        }, 2000);
      });

      setShowProgress(false);
    } catch (error) {
      setShowProgress(false);
    }
  };

  return (
    <div>
      <Container
        header={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Header data-testid="order-details-table-header" variant="h2">
              {intl.formatMessage({ id: "orderDetailsComponent.header.orderDetails" })}
            </Header>
            {isSyncWithSFDCEnabled && (
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  onClick={() => handleAction()}
                  disabled={showProgress}
                  variant="primary"
                  wrapText={false}
                  data-testid="sync-with-sfdc"
                >
                  {showProgress
                    ? intl.formatMessage({ id: "orderDetailsComponent.SFDC.inProgress" })
                    : intl.formatMessage({ id: "orderDetailsComponent.label.syncWithSFDC" })}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.customerName" })}>
              <div data-testid={"learningAccount-name"}>{props.order.learningAccount.name}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.orderId" })}>
              <div data-testid={"order-id"}>{props.order.id}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.salesforceOpportunityId" })}>
              <div data-testid={"opprotunity-id"}>{props.order.opportunityId}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.billingAwsAccountId" })}>
              <div data-testid={"aws-billing-accountId"}>
                {props.order.billingAwsAccountId ? props.order.billingAwsAccountId : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.typeOfContract" })}>
              <div data-testid={"typeOfContract"}>
                {props.order.typeOfContract ? props.order.typeOfContract : "NEW"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </div>
  );
}
