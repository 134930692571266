import { Button } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  AwsAssociation,
  CreateOrganizationInput,
  Organization,
  OrganizationBySfdcAccountIdQuery,
  OrganizationType,
  UpdateOrganizationInput,
} from "../../../__generated__/supergraph";
import { LEARNING_ACCOUNTS_ROUTE } from "../../common/routes";
import {
  useCreateOrderMutation,
  useCreateOrganizationMutation,
  useGetOpportunityInformationQuery,
  useOrganizationBySfdcAccountIdQuery,
  useOrganizationByVendorCode,
  useUpdateOrganizationMutation,
} from "../api";
import {
  getMetadataInput,
  sfdcErrorMessageMapping,
  trainingDeliveryTypeToProductModalityMap,
  validOpportunityId,
} from "../constants";
import {
  ContractingRequestCreatorInput,
  CreateOrderInput,
  CreateOrderItemInput,
  ErrorType,
  OrderId,
  PartialOrganization,
  PointOfContact,
  ProductModality,
  ProductOnboardingIdentityProvider,
  ReviewAndSubmitFulfillmentProps,
  ReviewAndSubmitLineItem,
  SalesforceOpportunity,
  TypeOfContract,
} from "../types";
import { useGetAllOrdersByOpportunityId } from "./useGetAllOrdersByOpportunityId";

const dtoAwsAssociationToOrganizationType: Record<AwsAssociation, OrganizationType> = {
  [AwsAssociation.AwsPartner]: OrganizationType.PartnersB2B,
  [AwsAssociation.None]: OrganizationType.CustomersB2B,
};

export const validateOpportunityId = (value: string) => {
  if (value.length === 18 && validOpportunityId.test(value)) {
    return true;
  }
  return false;
};

const createUpdateOrganizationInput = (
  organizationToUpdate: Organization,
  opportunity: SalesforceOpportunity
): UpdateOrganizationInput => {
  const awsAssociation = opportunity?.awsAccountInfo.awsAssociation;
  const organizationType =
    awsAssociation === AwsAssociation.AwsPartner ? OrganizationType.PartnersB2B : OrganizationType.CustomersB2B;
  return {
    id: organizationToUpdate?.id,
    awsAccountId: opportunity?.awsAccountInfo.accountId,
    name: opportunity?.sfdcAccountName ?? opportunity?.awsAccountInfo.name,
    sfdcAccountId: opportunity?.sfdcAccountId,
    sfdcParentAccountId: opportunity?.sfdcParentAccountId ?? undefined,
    sfdcParentAccountName: opportunity?.sfdcParentAccountName ?? undefined,
    billingAddress: organizationToUpdate?.billingAddress
      ? {
          name: opportunity?.awsAccountInfo.billingAddress.name ?? organizationToUpdate?.name, //This should never actually replace the billingAddress Name but just in case I'm putting it as a default.
          addressLineOne: opportunity?.awsAccountInfo.billingAddress.addressLineOne,
          addressLineTwo: organizationToUpdate?.billingAddress.addressLineTwo ?? undefined,
          city: opportunity?.awsAccountInfo.billingAddress.city,
          stateOrProvince: opportunity?.awsAccountInfo.billingAddress.stateOrProvince ?? undefined,
          countryCode: opportunity?.awsAccountInfo.billingAddress.countryCode,
          zipCode: opportunity?.awsAccountInfo.billingAddress.zipCode,
        }
      : undefined,
    status: organizationToUpdate?.status,
    phoneNumber: opportunity?.awsAccountInfo.billingAddress.phoneNumber ?? undefined,
    organizationType: organizationType,
  };
};

const createOrganizationInput = (opportunity: SalesforceOpportunity): CreateOrganizationInput => {
  const awsAssociation = opportunity?.awsAccountInfo.awsAssociation;
  const organizationType =
    awsAssociation === AwsAssociation.AwsPartner ? OrganizationType.PartnersB2B : OrganizationType.CustomersB2B;
  return {
    awsAccountId: opportunity?.awsAccountInfo.accountId,
    name: opportunity?.sfdcAccountName ?? opportunity?.awsAccountInfo.name,
    sfdcAccountId: opportunity?.sfdcAccountId,
    sfdcParentAccountId: opportunity?.sfdcParentAccountId ?? undefined,
    sfdcParentAccountName: opportunity?.sfdcParentAccountName ?? undefined,
    vendorCode: opportunity?.lmsId ?? undefined,
    phoneNumber: opportunity?.awsAccountInfo.billingAddress.phoneNumber ?? undefined,
    organizationType: organizationType,
  };
};

const isOrganizationNameChanged = (organizationName: string, opportunity: SalesforceOpportunity): boolean => {
  return opportunity.sfdcAccountName
    ? opportunity.sfdcAccountName !== organizationName
    : opportunity.awsAccountInfo.name !== organizationName;
};

const checkForDifferenceInOrganization = (opportunity: SalesforceOpportunity, organization: Organization): boolean => {
  if (
    opportunity.awsAccountInfo.accountId !== organization.awsAccountId ||
    isOrganizationNameChanged(organization.name, opportunity) ||
    opportunity.awsAccountInfo.billingAddress.phoneNumber !== organization.phoneNumber ||
    (organization.awsAssociation && opportunity.awsAccountInfo.awsAssociation !== organization.awsAssociation) ||
    opportunity.awsAccountInfo.billingAddress.name !== organization.billingAddress?.name ||
    opportunity.awsAccountInfo.billingAddress.addressLineOne !== organization.billingAddress?.addressLineOne ||
    opportunity.awsAccountInfo.billingAddress.city !== organization.billingAddress?.city ||
    opportunity.awsAccountInfo.billingAddress.stateOrProvince !== organization.billingAddress?.stateOrProvince ||
    opportunity.awsAccountInfo.billingAddress.countryCode !== organization.billingAddress?.countryCode ||
    opportunity.awsAccountInfo.billingAddress.zipCode !== organization.billingAddress?.zipCode
  ) {
    return true;
  }
  return false;
};

const createOrderInput = (opportunity: SalesforceOpportunity, learningAccountId: string): CreateOrderInput => {
  return {
    opportunityId: opportunity?.opportunityId,
    opportunityName: opportunity?.opportunityName,
    learningAccountId: learningAccountId,
    parentOpportunityId: opportunity?.sfdcParentOpportunityId ?? undefined,
    items:
      opportunity?.items
        .filter((lineItem) =>
          lineItem.trainingDeliveryType
            ? trainingDeliveryTypeToProductModalityMap[lineItem.trainingDeliveryType] === ProductModality.DIGITAL
            : false
        )
        .map((lineItem): CreateOrderItemInput => {
          return {
            quantity: lineItem.quantity,
            metadata: getMetadataInput(lineItem, opportunity),
            productModality: lineItem.trainingDeliveryType
              ? trainingDeliveryTypeToProductModalityMap[lineItem.trainingDeliveryType!]
              : undefined,
            subscriptionType: lineItem.subscriptionType ?? TypeOfContract.NEW,
            productTitle: lineItem.productName,
            unitPrice: lineItem.totalBilledRevenue / lineItem.quantity,
            subTotal: lineItem.totalBilledRevenue,
            ssoEnabled: lineItem.ssoDetails.ssoEnabled,
            productOnboardingDetails: {
              organizationName: opportunity.sfdcAccountName,
              authenticationMethod: lineItem.authenticationMethod ?? undefined,
              learningAdmins: [
                {
                  firstName: lineItem.primaryTrainingPOC.firstName,
                  lastName: lineItem.primaryTrainingPOC.lastName,
                  emailAddress: lineItem.primaryTrainingPOC.email,
                },
                ...(lineItem.secondaryTrainingPOC
                  ? [
                      {
                        firstName: lineItem.secondaryTrainingPOC.firstName,
                        lastName: lineItem.secondaryTrainingPOC.lastName,
                        emailAddress: lineItem.secondaryTrainingPOC.email,
                      },
                    ]
                  : []),
              ],
              emailDomains: lineItem.allowedEmailDomains,
              ssoContact: lineItem.ssoDetails?.technicalPOC
                ? {
                    firstName: lineItem.ssoDetails?.technicalPOC?.firstName ?? "",
                    lastName: lineItem.ssoDetails?.technicalPOC?.lastName ?? "",
                    emailAddress: lineItem.ssoDetails?.technicalPOC?.email ?? "",
                  }
                : undefined,
              identityProvider: lineItem.ssoDetails?.idp
                ? (lineItem.ssoDetails?.idp as unknown as ProductOnboardingIdentityProvider)
                : undefined,
            },
            billingPeriod: lineItem.billingPeriod,
            classEndDate: lineItem.classEndDate,
            classStartDate: lineItem.classStartDate,
            opportunityLineItemId: lineItem.opportunityLineItemId,
            contractingRequestCreator: lineItem.contractingRequestCreatedBy
              ? ({
                  email: lineItem.contractingRequestCreatedBy.email,
                  firstName: lineItem.contractingRequestCreatedBy.firstName,
                  lastName: lineItem.contractingRequestCreatedBy.lastName,
                  phone: lineItem.contractingRequestCreatedBy.phone,
                } as ContractingRequestCreatorInput)
              : undefined,
          };
        }) ?? [],
    noOfExistingLicenses: opportunity?.noOfExistingLicenses ?? 0,
    typeOfContract: opportunity?.typeOfContract ?? undefined,
    businessDevelopmentManager: {
      firstName: opportunity?.bdm?.firstName,
      lastName: opportunity?.bdm?.lastName,
      emailAddress: opportunity?.bdm?.email,
    },
  };
};

const convertOrganizationDtoToOrganization = (
  organizationDto: NonNullable<OrganizationBySfdcAccountIdQuery["organizationBySfdcAccountId"]>
): Organization => ({
  ...organizationDto,
  ...(organizationDto.billingAddress
    ? {
        billingAddress: {
          ...organizationDto.billingAddress,
          addressLineOne: organizationDto.billingAddress?.addressLineOne ?? "",
          addressLineTwo: organizationDto.billingAddress?.addressLineTwo ?? undefined,
          city: organizationDto.billingAddress?.city ?? "",
          stateOrProvince: organizationDto.billingAddress?.stateOrProvince ?? undefined,
          countryCode: organizationDto.billingAddress?.countryCode ?? "",
          zipCode: organizationDto.billingAddress?.zipCode ?? "",
        },
      }
    : { billingAddress: undefined }),
  status: organizationDto.status,
  vendorResourceCode: organizationDto.vendorResourceCode ?? undefined,
  organizationType: dtoAwsAssociationToOrganizationType[organizationDto.awsAssociation!],
  auditMetadata: organizationDto.auditMetadata,
});

export const useImportOpportunityFlow = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [requestStepIndex, setRequestStepIndex] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [fieldText, setFieldText] = useState("");
  const [validationError, setValidationError] = useState("");
  const [next, setNext] = useState(false);
  const [opportunityObj, setOpportunityObj] = useState<SalesforceOpportunity>();
  const [sfdcAccountId, setSfdcAccountId] = useState("");
  const [vendorCode, setVendorCode] = useState("");
  const [organization, setOrganization] = useState<PartialOrganization>();
  const [fullOrganization, setFullOrganization] = useState<Organization>();
  const [order, setOrder] = useState<OrderId>();
  const [opportunityError, setOpportunityError] = useState<ErrorType[]>([]);
  const [flashbarItems, setFlashbarItems] = useState([{}]);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [successFlashbarItems, setSuccessFlashbarItems] = useState([{}]);
  const [detailsCardProps, setDetailsCardProps] = useState({});
  const [itemsTableProps, setItemsTableProps] = useState<ReviewAndSubmitLineItem[]>([]);
  const [fulfillmentDetailsProps, setFulfillmentDetailsProps] = useState<ReviewAndSubmitFulfillmentProps>();
  //this variable is used to control next button loading. If it is set to false, the next button will not load
  const [loadingOverride, setLoadingOverride] = useState(false);

  const setSuccessFlashbar = async () => {
    setOrderSuccess(true);
    setSuccessFlashbarItems([
      {
        type: "success",
        content: intl.formatMessage({ id: "importOpportunity.flashbar.success.onboardingSuccess" }),
        dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
        dismissible: true,
        onDismiss: () => {
          setSuccessFlashbarItems([]);
        },
        id: "successFlashbar",
      },
    ]);
  };

  const setErrorFlashbar = async (errors: ErrorType[]) => {
    const items: object[] = [];
    errors.forEach((error: ErrorType, index: number) => {
      switch (error) {
        case ErrorType.ONBOARDING_FAILED: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.onboardingFailed" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.ONBOARDING_FAILED)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.NON_UNIQUE_OPPORTUNITY: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.nonUniqueOpportunity" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            action: (
              <Button
                onClick={() => navigate(`${LEARNING_ACCOUNTS_ROUTE}/${order?.learningAccount.id}/orders/${order?.id}`)}
              >
                {" "}
                {intl.formatMessage({ id: "getOpportunityComponent.nonUniqueOpportunity.navigateToOrder" })}{" "}
              </Button>
            ),
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.NON_UNIQUE_OPPORTUNITY)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.MISSING_LMS_ID: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.missingLmsId" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.MISSING_LMS_ID)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.MULTIPLE_OPPORTUNITIES_ALL_IMPORTED: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.multipleOpportunitiesAllImported" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            action: (
              <Button onClick={() => navigate(`${LEARNING_ACCOUNTS_ROUTE}/${order?.learningAccount.id}/orders/`)}>
                {" "}
                {intl.formatMessage({ id: "getOpportunityComponent.nonUniqueOpportunity.navigateToOrganization" })}{" "}
              </Button>
            ),
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter(
                  (thisError: ErrorType) => thisError !== ErrorType.MULTIPLE_OPPORTUNITIES_ALL_IMPORTED
                )
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.NETWORK_ERROR: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.defaultsfdc" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.NETWORK_ERROR)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.UNKNOWN_ERROR: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.defaultsfdc" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.UNKNOWN_ERROR)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_DNE: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.opportunityDoesNotExist" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_DNE)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_LINE_ITEM_MISSING: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.lineItemDataNotFound" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_LINE_ITEM_MISSING)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_MISSING_AWS_ACCOUNT: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.missingAWSAccount" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter(
                  (thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_MISSING_AWS_ACCOUNT
                )
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_INVALID_RECORD_TYPE: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidRecordType" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter(
                  (thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_INVALID_RECORD_TYPE
                )
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_INVALID_AWS_ACCOUNT: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidAWSIDinOpportunity" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter(
                  (thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_INVALID_AWS_ACCOUNT
                )
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_MISSING_ADDRESS: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.missingAddressDataInAwsAccount" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_MISSING_ADDRESS)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_NO_COUNTRYCODE: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.noCountryCode" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_NO_COUNTRYCODE)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.OPPORTUNITY_INVALID_COUNTRYCODE: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidCountryCode" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter(
                  (thisError: ErrorType) => thisError !== ErrorType.OPPORTUNITY_INVALID_COUNTRYCODE
                )
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.SFDC_ERROR: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.defaultsfdc" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.SFDC_ERROR)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.SFDC_SSO_ERROR: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidSSO" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.SFDC_SSO_ERROR)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.INVALID_ALLOWED_EMAIL_DOMAIN: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidAllowedEmailDomain" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.INVALID_ALLOWED_EMAIL_DOMAIN)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.SAME_PRIMARY_AND_SECONDARY_EMAIL: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.samePrimaryAndSecondaryEmail" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter(
                  (thisError: ErrorType) => thisError !== ErrorType.SAME_PRIMARY_AND_SECONDARY_EMAIL
                )
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.INVALID_PRODUCT_NAME: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidProductName" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.INVALID_PRODUCT_NAME)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.MULTIPLE_PRODUCTS: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.multipleProducts" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.MULTIPLE_PRODUCTS)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.MULTIPLE_PTRRS: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.multiplePtrrs" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.MULTIPLE_PTRRS)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.INVALID_AUTH_METHOD: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidAuthenticationMethod" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.INVALID_AUTH_METHOD)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.INVALID_IDENTITY_PROVIDER: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidIdentityProvider" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.INVALID_IDENTITY_PROVIDER)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.MIDTERM_ADDITION_CONTRACT_TYPE: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.midtermAdditionUnsupported" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter(
                  (thisError: ErrorType) => thisError !== ErrorType.MIDTERM_ADDITION_CONTRACT_TYPE
                )
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.AUTH_METHOD_MISSING: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.missingAuthenticationMethod" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.AUTH_METHOD_MISSING)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.MULTIPLE_AWS_ACCOUNTS: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.multipleAWSAccounts" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.MULTIPLE_AWS_ACCOUNTS)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.CLASS_END_MISSING: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.missingClassEndDate" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.CLASS_END_MISSING)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.CLASS_END_INVALID: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidClassEndDate" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.CLASS_END_INVALID)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.INVALID_SEATS_COUNT: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.invalidSeatsCount" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.INVALID_SEATS_COUNT)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.NO_PRIMARY_EMAIL: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.noPrimaryEmail" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.NO_PRIMARY_EMAIL)
              );
              setSubmit(false);
            },
          });
          break;
        }

        case ErrorType.REGION_NOT_SUPPORTED: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.regionNotSupported" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.REGION_NOT_SUPPORTED)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.BCF_NOT_FOUND: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.bcfNotFound" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.BCF_NOT_FOUND)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.ORG_CREATION_ERROR: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.orgCreationFailure" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.ORG_CREATION_ERROR)
              );
              setSubmit(false);
            },
          });
          break;
        }
        case ErrorType.INCORRECT_LMS_ID: {
          items.push({
            type: "error",
            content: intl.formatMessage({ id: "importOpportunity.flashbar.error.incorrectLmsId" }),
            dismissLabel: intl.formatMessage({ id: "learningAccountsTableComponent.flashbar.dismissLabel" }),
            dismissible: true,
            id: index,
            onDismiss: () => {
              setFlashbarItems(() => flashbarItems.filter((item: any) => item.id !== index));
              setOpportunityError(() =>
                opportunityError.filter((thisError: ErrorType) => thisError !== ErrorType.INCORRECT_LMS_ID)
              );
              setSubmit(false);
            },
          });
          break;
        }
      }
    });

    setFlashbarItems(items);
  };

  //Setup the Flashbar to get triggered any time there is an Error
  useEffect(() => {
    if (opportunityError.length !== 0) {
      setErrorFlashbar(opportunityError);
      opportunityError.forEach((error: ErrorType) => {
        if (error !== ErrorType.ONBOARDING_FAILED) {
          setNext(false);
        }
      });
    }
  }, [opportunityError]);

  //GraphQL API Calls
  const {
    setOpportunityId: getOrdersByOpportunityId,
    requestState: {
      data: ordersByOpportunityData,
      loading: ordersByOpportunityLoading,
      error: ordersByOpportunityError,
    },
  } = useGetAllOrdersByOpportunityId();

  const [
    getOrderOpportunityInformation,
    { loading: sfdcOpportunityLoading, data: sfdcOpportunityData, error: sfdcOpportunityError },
  ] = useGetOpportunityInformationQuery(); //Get the opportunity object

  const [
    getOrganizationBySfdcAccountId,
    {
      loading: organizationBySfdcAccountIdLoading,
      data: organizationBySfdcAccountIdData,
      error: organizationBySfdcAccountIdError,
    },
  ] = useOrganizationBySfdcAccountIdQuery({ sfdcAccountId }); // Get learning account by opportunity's SFDC account Id.

  const [
    getLearningAccountBySkillBuilderBranchCode,
    {
      loading: organizationByVendorCodeLoading,
      data: organizationByVendorCodeData,
      error: organizationByVendorCodeError,
    },
  ] = useOrganizationByVendorCode({ vendorCode }); // Get learning account by opportunity's SB branch code.

  const [
    updateOrganization,
    { data: updateOrganizationData, loading: updateOrganizationLoading, error: updateOrganizationError },
  ] = useUpdateOrganizationMutation(createUpdateOrganizationInput(fullOrganization!, opportunityObj!)); //Update a learning account

  const [
    createOrganization,
    { data: createOrganizationData, loading: createOrganizationLoading, error: createOrganizationError },
  ] = useCreateOrganizationMutation(createOrganizationInput(opportunityObj!)); //Create a learning account

  const [createOrder, { loading: createOrderLoading, error: createOrderError, data: createOrderData }] =
    useCreateOrderMutation(createOrderInput(opportunityObj!, organization?.id ?? "")); //Create an order from opportunity

  //override loading setting for when flashbar loads
  useEffect(() => {
    if (flashbarItems.length > 0) {
      setLoadingOverride(false);
    }
  }, [flashbarItems]);

  //useEffects in order of expected business workflow.
  useEffect(() => {
    setNext(validateOpportunityId(fieldText));
    setValidationError("");
  }, [fieldText]); // Updates based on input field

  useEffect(() => {
    if (!next) {
      setRequestStepIndex(0);
      setActiveStepIndex(0);
    }
  }, [next]); // Forces any errors to cause the page to stay on the first view.

  useEffect(() => {
    const sfdcRequestCompletedSuccessfully =
      !sfdcOpportunityError && !sfdcOpportunityLoading && sfdcOpportunityData?.getSalesforceOpportunityById;
    const ordersRequestCompletedSuccessfully =
      !ordersByOpportunityError && !ordersByOpportunityLoading && ordersByOpportunityData;

    // Handle any errors for both requests
    if (sfdcOpportunityError && fieldText !== "") {
      let errorSet = false;
      const errors: ErrorType[] = [];

      for (const map of sfdcErrorMessageMapping) {
        if (sfdcOpportunityError.message.includes(map.errorMessage) && map.errorType !== ErrorType.SFDC_ERROR) {
          errors.push(map.errorType);
          errorSet = true;
        }
      }

      if (!errorSet) {
        setOpportunityError([ErrorType.SFDC_ERROR]);
      } else if (opportunityError[0] === ErrorType.NON_UNIQUE_OPPORTUNITY) {
        setOpportunityError([ErrorType.NON_UNIQUE_OPPORTUNITY, ...errors]);
      } else {
        setOpportunityError(errors);
      }

      setNext(false);
    } else if (sfdcOpportunityData?.getSalesforceOpportunityById === null) {
      setOpportunityError([ErrorType.NETWORK_ERROR]);
      setNext(false);
    } else if (ordersByOpportunityError && fieldText !== "") {
      setOpportunityError([ErrorType.NETWORK_ERROR]);
      setNext(false);
    }

    // Validate SFDC opportunity lines against any returned orders, requires SFDC and OrderService query responses
    if (sfdcRequestCompletedSuccessfully && ordersRequestCompletedSuccessfully && next) {
      const sfdcOpportunityLineItems = sfdcOpportunityData.getSalesforceOpportunityById.items;
      const processedLineItemIds = ordersByOpportunityData.lineItems as string[];
      const processedOrders = ordersByOpportunityData.orders as OrderId[];
      const unprocessedItems = processedLineItemIds.length
        ? sfdcOpportunityLineItems.filter((sfdcItem: any) => {
            return !processedLineItemIds.includes(sfdcItem.opportunityLineItemId);
          })
        : sfdcOpportunityLineItems;

      if (!unprocessedItems.length && !processedOrders.length) {
        setOpportunityError([ErrorType.OPPORTUNITY_LINE_ITEM_MISSING]);
        setNext(false);
      }

      // All SFDC line items have corresponding orderItems, opportunity is already imported
      else if (!unprocessedItems.length && processedOrders.length) {
        if (processedOrders.length === 1) {
          setOpportunityError([ErrorType.NON_UNIQUE_OPPORTUNITY]);
        } else {
          //If there are multiple imported orders link to the learning account
          setOpportunityError([ErrorType.MULTIPLE_OPPORTUNITIES_ALL_IMPORTED]);
        }
        setOrder(processedOrders[0] as unknown as OrderId);
        setNext(false);
      }
      // There are opportunity line items that haven't been processed
      else {
        setLoadingOverride(false);
        const opportunityData = sfdcOpportunityData?.getSalesforceOpportunityById as SalesforceOpportunity;
        // Filter out any processed lines, we only reach this point if there are unprocessed opportunity lines
        opportunityData.items = opportunityData.items.filter((lineItem) => {
          return !ordersByOpportunityData.lineItems.includes(lineItem.opportunityLineItemId);
        });

        // Continue filtering only digital on frontend
        opportunityData.items = opportunityData.items.filter((lineItem) =>
          lineItem.trainingDeliveryType
            ? trainingDeliveryTypeToProductModalityMap[lineItem.trainingDeliveryType] === ProductModality.DIGITAL
            : false
        );

        setOpportunityObj(opportunityData);

        setDetailsCardProps({
          customer: opportunityData.awsAccountInfo.name,
          orderId: "Pending Creation",
          salesforceOpportunityId: opportunityData.opportunityId,
          typeOfContract: opportunityData.typeOfContract,
          accountId: opportunityData.awsAccountInfo.accountId,
        });

        setItemsTableProps(
          opportunityData.items.map((item) => {
            return {
              productName: item.productName,
              quantity: item.quantity,
              discountAmount: item.discountAmount,
              orderTotal: item.totalBilledRevenue,
            } as ReviewAndSubmitLineItem;
          })
        );

        setFulfillmentDetailsProps({
          customerName: opportunityData.awsAccountInfo.name,
          branchId: "Pending",
          identityProvider: opportunityData.items[0].ssoDetails.idp ?? "",
          ssoContact: opportunityData.items[0].ssoDetails.technicalPOC,
          allowedEmailDomains: opportunityData.items[0].allowedEmailDomains,
          learningAdmins: [
            opportunityData.items[0].primaryTrainingPOC,
            opportunityData.items[0].secondaryTrainingPOC,
          ].filter((item) => item) as PointOfContact[],
          ssoEnabled: opportunityData.items[0].ssoDetails.ssoEnabled,
          classStartDate: opportunityData.items[0].classStartDate,
          classEndDate: opportunityData.items[0].classEndDate,
          authenticationMethod: opportunityData.items[0].authenticationMethod ?? undefined,
        });
      }
    }
  }, [ordersByOpportunityLoading, sfdcOpportunityLoading]);

  useEffect(() => {
    setSfdcAccountId(opportunityObj?.sfdcAccountId ?? "");
    if (opportunityObj?.sfdcAccountId !== undefined) {
      getOrganizationBySfdcAccountId();
    }
    setVendorCode(opportunityObj?.lmsId ?? "");
    if (opportunityObj?.lmsId !== undefined) {
      getLearningAccountBySkillBuilderBranchCode();
    }
  }, [opportunityObj]); // When the opportunity object updates, try to look up the learning account by SFDC account Id or SB branch code.

  useEffect(() => {
    if (organizationBySfdcAccountIdLoading || organizationByVendorCodeLoading) {
      return;
    }
    //if lmsId is populated and does not match the lmsId placed on the org
    if (
      !organizationByVendorCodeData?.organizationByVendorCode &&
      organizationBySfdcAccountIdData?.organizationBySfdcAccountId &&
      vendorCode
    ) {
      setOpportunityError([ErrorType.INCORRECT_LMS_ID]);
      return;
    }
    const organizationData =
      organizationBySfdcAccountIdData?.organizationBySfdcAccountId ??
      organizationByVendorCodeData?.organizationByVendorCode;
    if (organizationBySfdcAccountIdError && organizationByVendorCodeError) {
      setOpportunityError([ErrorType.UNKNOWN_ERROR]);
      setNext(false);
    } else if (!organizationData && sfdcAccountId !== "") {
      //learning account is not found and we are creating the learning account
      createOrganization();
    } else if (organizationData) {
      setFullOrganization(convertOrganizationDtoToOrganization(organizationData));
      const partial: PartialOrganization = {
        id: organizationData.id,
        awsAccountId: organizationData?.awsAccountId,
        name: organizationData.name,
      };
      setOrganization(partial);
    }

    if (next) {
      setActiveStepIndex(requestStepIndex);
      setFlashbarItems([]);
    }
  }, [
    organizationBySfdcAccountIdLoading,
    organizationBySfdcAccountIdData,
    organizationBySfdcAccountIdError,
    organizationByVendorCodeLoading,
    organizationByVendorCodeData,
    organizationByVendorCodeError,
  ]); // Check to see if we found an associated learning account. If we didn't, create one.

  useEffect(() => {
    if (
      organization &&
      fullOrganization &&
      opportunityObj &&
      checkForDifferenceInOrganization(opportunityObj, fullOrganization)
    ) {
      updateOrganization();
    }
  }, [organization, fullOrganization]);

  useEffect(() => {
    if (updateOrganizationError && organization && fullOrganization) {
      //Show Error, We can define a specific error for this case but for now we'll display generic error.
      setOpportunityError([ErrorType.ORG_CREATION_ERROR]);
    }
  }, [updateOrganizationData, updateOrganizationLoading, updateOrganizationError]);

  useEffect(() => {
    if (createOrganizationError && sfdcAccountId !== "") {
      setOpportunityError([ErrorType.ORG_CREATION_ERROR]);
      setNext(false);
    } else if (sfdcAccountId !== "" && createOrganizationData && createOrganizationData?.createOrganization !== null) {
      setOrganization(createOrganizationData?.createOrganization as PartialOrganization);
    }
  }, [createOrganizationData, createOrganizationError, createOrganizationLoading]); // Creates the order and displays any errors.

  useEffect(() => {
    if (createOrderError) {
      //Throw Error Flashbar
      setOpportunityError([ErrorType.ONBOARDING_FAILED]);
    } else if (createOrderData?.createOrder) {
      setSuccessFlashbar();
      const newOrder = createOrderData?.createOrder as OrderId;
      navigate(`${LEARNING_ACCOUNTS_ROUTE}/${newOrder?.learningAccount.id}/orders/${newOrder?.id}`);
    }
    setSubmit(false);
  }, [createOrderData, createOrderError, createOrderLoading]);

  const onSubmit = () => {
    if (opportunityObj && organization && !createOrderLoading) {
      createOrder();
    }
  };

  useEffect(() => {
    if (submit) {
      setLoadingOverride(true);
      onSubmit();
    }
  }, [submit]); // Once the submit button is hit, the submit workflow is started and order creation begins.

  // Action Functions
  const onInputChange = (value: any) => {
    setValidationError(""); //reset the error in the text field input
    setFieldText(value);
  };

  const onRequestStepIndexChange = (value: any) => {
    setRequestStepIndex(value);
  };

  const onCancel = () => {
    setActiveStepIndex(0);
    setFieldText("");
    setFlashbarItems([]);
    setNext(false);
    setRequestStepIndex(0);
    setValidationError("");
    setOpportunityError([]);
    setOpportunityObj(undefined);
    setSfdcAccountId("");
    setVendorCode("");
    setOrganization(undefined);
    setFullOrganization(undefined);
    setOrder(undefined);
    setDetailsCardProps({});
    setItemsTableProps([]);
    setFulfillmentDetailsProps(undefined);
  };

  const onNavigateFunction = (detail: any) => {
    onRequestStepIndexChange(detail.requestedStepIndex);
    setNext(true);
    if (activeStepIndex === 0) {
      if (!validateOpportunityId(fieldText)) {
        setValidationError(intl.formatMessage({ id: "getOpportunityComponent.invalidOpportunityId" }));
        setNext(false);
      } else {
        setLoadingOverride(true);
        setOpportunityError([]);
        setFlashbarItems([]);
        getOrderOpportunityInformation();
        if (fieldText !== "") {
          getOrderOpportunityInformation({ variables: { opportunityId: fieldText } });
          getOrdersByOpportunityId({ value: fieldText });
        }
      }
    } else if (activeStepIndex !== 0) {
      setOpportunityError([]);
      setOpportunityObj(undefined);
      setSubmit(false);
      setActiveStepIndex(detail.requestedStepIndex);
      setFlashbarItems([]);
    }
  };

  const onReviewAndSubmit = () => {
    setSubmit(true);
  };

  //Return Statement
  return {
    fieldText,
    validationError,
    detailsCardProps,
    itemsTableProps,
    fulfillmentDetailsProps,
    flashbarItems,
    opportunityError,
    activeStepIndex,
    ordersByOpportunityLoading,
    sfdcOpportunityLoading,
    organizationLoading: organizationBySfdcAccountIdLoading || organizationByVendorCodeLoading,
    createOrganizationLoading,
    updateOrganizationLoading,
    createOrderLoading,
    successFlashbarItems,
    orderSuccess,
    loadingOverride,
    onCancel,
    onReviewAndSubmit,
    onInputChange,
    onNavigateFunction,
  };
};
