import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  ButtonDropdown,
  Modal,
  Button,
  FormField,
  Input,
  DatePicker,
  DatePickerProps,
  InputProps,
} from "@amzn/awsui-components-react";
import { FormattedDate, useIntl } from "react-intl";
import { B2BBillingStatus, OrderItem } from "../types";
import { ProductModality } from "../../onboarding/types";
import { useState } from "react";
import { billingActionsEnabled } from "../../common/featureFlags";
import React = require("react");

interface OrderProps {
  orderItem: OrderItem[];
}

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

const todaysDate = new Date().toISOString().slice(0, 10).replaceAll("-", "/");

const myComponentStyle = {
  margin: "10px",
};

const convertBillingStatus = (billingStatus: B2BBillingStatus) => {
  switch (billingStatus) {
    case B2BBillingStatus.TO_BE_BILLED:
      return "To Be Billed";
    case B2BBillingStatus.BILL_IMMEDIATELY:
      return "Bill Immediately";
    case B2BBillingStatus.BILLING_IN_PROGRESS:
      return "Billing in Progress";
    case B2BBillingStatus.BILLED:
      return "Billed";
    case B2BBillingStatus.BILLING_FAILED:
      return "Billing Failed";
    case B2BBillingStatus.NOT_APPLICABLE:
      return "Not Applicable";
  }
};

const getMetadataField = (orderItem: OrderItem, fieldName: string) => {
  if (orderItem.metadata) {
    const response = orderItem.metadata.find((item) => item.key === fieldName);
    if (response) {
      return JSON.parse(response.value);
    }
  }
  return "-";
};

export function BillingDetails(props: OrderProps): JSX.Element {
  const intl = useIntl();
  const [showProgress, setShowProgress] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceDate, setInvoiceDate] = React.useState(todaysDate);
  const [invoiceId, setInvoiceId] = React.useState("");
  const [attemptedEntry, setAttemptedEntry] = useState(false);
  const orderItem = props.orderItem.filter((item) => item.productModality === ProductModality.DIGITAL)[0];
  const isBillingActionsEnabled = billingActionsEnabled();
  const handleAction = async (actionType: string, actionData?: { invoiceId: string; invoiceDate: string }) => {
    try {
      setShowProgress(true);
      setSelectedAction(actionType);

      // We can send action data to some other API here
      // For now, we send it to console
      if (actionData) {
        console.log("Action Data:", actionData.invoiceId, actionData.invoiceDate);
      }

      // Simulate API call
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          Math.random() > 0.5 ? resolve(null) : reject(new Error());
        }, 2000);
      });

      setShowProgress(false);
      setSelectedAction(null);
    } catch (error) {
      setShowProgress(false);
      setSelectedAction(null);
    }
  };

  const actionItems = [
    {
      id: "toBeBilled",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.toBeBilled.button" }),
    },
    {
      id: "billImmediately",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billImmediately.button" }),
    },
    {
      id: "billClipper",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.button" }),
    },
    {
      id: "toBeAccrued",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.toBeAccrued.button" }),
    },
    {
      id: "accrued",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.accrued.button" }),
    },
  ];

  const handleActionDropdown = ({ detail }: { detail: { id: string } }) => {
    if (detail.id === "billClipper") {
      setIsModalVisible(true);
    } else {
      handleAction(detail.id);
    }
  };

  const handleModalDismiss = () => {
    setIsModalVisible(false);
  };

  const handleModalConfirm = () => {
    handleAction("billClipper", {
      invoiceId,
      invoiceDate,
    });

    setIsModalVisible(false);
    // Reset the form
    setInvoiceId("");
    setInvoiceDate(todaysDate);
  };

  return (
    <div>
      <Container
        header={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Header
              data-testid="billing-details-table-header"
              variant="h2"
              actions={
                isBillingActionsEnabled && (
                  <SpaceBetween direction="horizontal" size="xs">
                    <ButtonDropdown
                      items={actionItems}
                      onItemClick={handleActionDropdown}
                      disabled={showProgress}
                      variant="primary"
                      data-testid="actions-button"
                    >
                      {selectedAction !== null
                        ? intl.formatMessage({
                            id: `orderBillingDetailsComponent.actions.${selectedAction}.inProgress`,
                          })
                        : intl.formatMessage({ id: "orderBillingDetailsComponent.actions.button" })}
                    </ButtonDropdown>
                  </SpaceBetween>
                )
              }
            >
              {intl.formatMessage({ id: "orderBillingDetailsComponent.header.billingDetails" })}
            </Header>
          </div>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.billingStatus" })}>
              <div data-testid={"billingStatus"}>
                {orderItem && orderItem.nmbsBillingDetails?.billingStatus
                  ? convertBillingStatus(orderItem.nmbsBillingDetails?.billingStatus)
                  : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.endUserAWSAccountId" })}
            >
              <div data-testid={"endUserAWSAccountId"}>{getMetadataField(orderItem, "awsEndUserAccount")}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.poNumber" })}>
              <div data-testid={"poNumber"}>{getMetadataField(orderItem, "poNumberOnInvoice")}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.productName" })}>
              <div data-testid={"productName"}>{orderItem.productTitle}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceAmount" })}>
              <div data-testid={"invoiceAmount"}>
                {orderItem.subTotal && orderItem.nmbsBillingDetails?.billingStatus === B2BBillingStatus.BILLED
                  ? intl.formatNumber(orderItem.subTotal, { style: "currency", currency: "USD" })
                  : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceNumber" })}>
              <div data-testid={"invoiceNumber"}>
                {orderItem.nmbsBillingDetails?.invoiceId ? (
                  <Link href={orderItem.nmbsBillingDetails?.billingConsoleUILink} target="_blank">
                    {orderItem.nmbsBillingDetails?.invoiceId}
                  </Link>
                ) : (
                  "-"
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceDate" })}>
              <div data-testid={"invoiceDate"}>
                {orderItem.nmbsBillingDetails?.billingStatus === B2BBillingStatus.BILLED ? (
                  <span title={intl.formatDate(orderItem.nmbsBillingDetails?.billingStatusUpdatedAt)}>
                    <FormattedDate value={orderItem.nmbsBillingDetails?.billingStatusUpdatedAt} />
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.errorMessage" })}>
              <div data-testid={"errorMessage"}>{orderItem.nmbsBillingDetails?.billingErrorMessage || "-"}</div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
      <Modal
        onDismiss={handleModalDismiss}
        visible={isModalVisible}
        closeAriaLabel="Close modal"
        header={intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.header" })}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={handleModalDismiss} data-testid="cancel-button">
                {intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.cancel" })}
              </Button>
              <Button variant="primary" onClick={handleModalConfirm} disabled={!invoiceId} data-testid="save-button">
                {intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.save" })}
              </Button>
            </SpaceBetween>
          </Box>
        }
        data-testid="clipper-modal"
      >
        <SpaceBetween size="m">
          <Box variant="p" data-testid="get-invoice-information-box">
            {intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.getInvoiceInformation" })}
          </Box>
          <FormField
            label={intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceIdLabel" })}
            errorText={
              !invoiceId && attemptedEntry
                ? intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceIdNotGiven" })
                : undefined
            }
            data-testid="get-invoice-id"
          >
            <Input
              value={invoiceId}
              onChange={({ detail }: { detail: InputProps.ChangeDetail }) => {
                setAttemptedEntry(true);
                setInvoiceId(detail.value);
              }}
              placeholder={intl.formatMessage({
                id: "orderBillingDetailsComponent.actions.billClipper.modal.getInvoiceId",
              })}
              type="text"
            />
          </FormField>
          <FormField
            label={intl.formatMessage({
              id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceDateLabel",
            })}
            constraintText={intl.formatMessage({
              id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceDateFormat",
            })}
            data-testid="get-invoice-date"
          >
            <DatePicker
              onChange={({ detail }: { detail: DatePickerProps.ChangeDetail }) =>
                setInvoiceDate(detail.value.replaceAll("-", "/"))
              }
              value={invoiceDate}
              placeholder="YYYY/MM/DD"
              data-testid="invoice-date-picker"
            />
          </FormField>
        </SpaceBetween>
      </Modal>
    </div>
  );
}
