import { AwsAssociation } from "../../__generated__/supergraph";

export enum ProductOnboardingIdentityProvider {
  ADFS = "ADFS",
  AZURE = "AZURE",
  PING = "PING",
  OKTA = "OKTA",
  DUO = "DUO",
  GOOGLE = "GOOGLE",
  JUMP = "JUMP",
  ONELOGIN = "ONELOGIN",
}

export enum BillingPeriod {
  MONTHLY = "MONTHLY",
  ANNUAL = "ANNUAL",
}

export enum LearningAccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface ReviewAndSubmitLineItem {
  productName: string;
  quantity: number;
  discountAmount: number;
  orderTotal: number;
}

export enum TypeOfContract {
  NEW = "NEW",
  MID_TERM_ADDITION = "MID_TERM_ADDITION",
  RENEWAL = "RENEWAL",
  UPGRADE = "UPGRADE",
}

export enum ProductOnboardingAuthenticationMethod {
  LWA = "LWA",
  SSO = "SSO",
  APN = "APN",
  AWS_BUILDER_ID = "AWS_BUILDER_ID",
}

export enum ProductModality {
  DIGITAL = "DIGITAL",
  ILT = "ILT",
}

export enum ErrorType {
  NON_UNIQUE_OPPORTUNITY = "NON_UNIQUE_OPPORTUNITY",
  MULTIPLE_OPPORTUNITIES_ALL_IMPORTED = "MULTIPLE_OPPORTOUNITIES_ALL_IMPORTED",
  OPPORTUNITY_DNE = "OPPORTUNITY_DNE",
  OPPORTUNITY_LINE_ITEM_MISSING = "OPPORTUNITY_LINE_ITEM_MISSING",
  OPPORTUNITY_MISSING_AWS_ACCOUNT = "OPPORTUNITY_MISSING_AWS_ACCOUNT",
  OPPORTUNITY_INVALID_RECORD_TYPE = "OPPORTUNITY_INVALID_RECORD_TYPE",
  OPPORTUNITY_INVALID_AWS_ACCOUNT = "OPPORTUNITY_INVALID_AWS_ACCOUNT",
  OPPORTUNITY_MISSING_ADDRESS = "OPPORTUNITY_MISSING_ADDRESS",
  OPPORTUNITY_NO_COUNTRYCODE = "OPPORTUNITY_NO_COUNTRYCODE",
  OPPORTUNITY_INVALID_COUNTRYCODE = "OPPORTUNITY_INVALID_COUNTRYCODE",
  SFDC_SSO_ERROR = "SFDC_SSO_ERROR",
  INVALID_ALLOWED_EMAIL_DOMAIN = "INVALID_ALLOWED_EMAIL_DOMAIN",
  SAME_PRIMARY_AND_SECONDARY_EMAIL = "SAME_PRIMARY_AND_SECONDARY_EMAIL",
  INVALID_PRODUCT_NAME = "INVALID_PRODUCT_NAME",
  MULTIPLE_PRODUCTS = "MULTIPLE_PRODUCTS",
  MULTIPLE_PTRRS = "MULTIPLE_PTRRS",
  INVALID_AUTH_METHOD = "INVALID_AUTH_METHOD",
  INVALID_IDENTITY_PROVIDER = "INVALID_IDENTITY_PROVIDER",
  MIDTERM_ADDITION_CONTRACT_TYPE = "MIDTERM_ADDITION_CONTRACT_TYPE",
  AUTH_METHOD_MISSING = "AUTH_METHOD_MISSING",
  MULTIPLE_AWS_ACCOUNTS = "MULTIPLE_AWS_ACCOUNTS",
  CLASS_END_MISSING = "CLASS_END_MISSING",
  CLASS_END_INVALID = "CLASS_END_INVALID",
  INVALID_SEATS_COUNT = "INVALID_SEATS_COUNT",
  REGION_NOT_SUPPORTED = "REGION_NOT_SUPPORTED",
  NO_PRIMARY_EMAIL = "NO_PRIMARY_EMAIL",
  SFDC_ERROR = "SFDC_ERROR",
  MISSING_LMS_ID = "MISSING_LMS_ID",
  ONBOARDING_FAILED = "ONBOARDING_FAILED",
  NETWORK_ERROR = "NETWORK_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  NO_ERROR = "NO_ERROR",
  BCF_NOT_FOUND = "BCF_NOT_FOUND",
  ORG_CREATION_ERROR = "ORG_CREATION_ERROR",
  INCORRECT_LMS_ID = "INCORRECT_LMS_ID",
}

export type Address = {
  name?: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  phoneNumber?: string;
  stateOrProvince?: string;
  countryCode: string;
  zipCode: string;
};

export type AuditMetadata = {
  createdBy: string;
  createdAt: string;
  lastUpdatedBy: string;
  lastUpdatedAt: string;
};

export type PointOfContact = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

export interface ReviewAndSubmitFulfillmentProps {
  customerName: string;
  branchId: string;
  identityProvider: string;
  allowedEmailDomains: string[];
  ssoContact?: PointOfContact;
  learningAdmins: PointOfContact[];
  ssoEnabled: boolean;
  classStartDate: string;
  classEndDate: string;
  authenticationMethod?: ProductOnboardingAuthenticationMethod | null;
}

export type SSODetails = {
  ssoEnabled: boolean;
  idp?: string;
  technicalPOC?: PointOfContact;
};

export type ContractingRequestCreatorInput = {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
};

export type PTRR = {
  accountName: string;
  address1: string;
  address2: string;
  awsBillingAccount: string;
  awsEndUserAccount: string;
  billingNotes: string;
  billingTerms: string;
  city: string;
  country: string;
  createdDate: string;
  customerTaxVATId: string;
  geo: string;
  legalEntityNameTraining: string;
  opportunityName: string;
  ownerId: string;
  paymentMethod: string;
  poNumberOnInvoice: string;
  ptfTemplateName: string;
  ptrrId: string;
  recordTypeId: string;
  region: string;
  state: string;
  topsName: string;
  trainingBillingContactEmail: string;
  trainingBillingContactName: string;
  zipCode: string;
};

export type SalesforceOpportunityLineItem = {
  opportunityLineItemId: string;
  productName: string;
  quantity: number;
  classStartDate: string;
  classEndDate: string;
  totalBilledRevenue: number;
  discountAmount: number;
  totalPrice: number;
  billingPeriod: BillingPeriod;
  primaryTrainingPOC: PointOfContact;
  secondaryTrainingPOC?: PointOfContact;
  allowedEmailDomains: string[];
  ssoDetails: SSODetails;
  authenticationMethod?: ProductOnboardingAuthenticationMethod;
  contractingRequestCreatedBy?: PointOfContact;
  subscriptionType?: TypeOfContract;
  trainingDeliveryType?: string;
  timeZone?: string;
  deliveryLanguage?: string;
  classLocation?: string;
  currencyCode?: string;
  contractingPTRR?: PTRR;
};

export type AWSAccountInfo = {
  accountId: string;
  name: string;
  status?: string;
  awsAssociation: AwsAssociation;
  billingAddress: Address;
};

export type SalesforceOpportunity = {
  opportunityId: string;
  opportunityName: string;
  grandTotal: number;
  awsAccountInfo: AWSAccountInfo;
  items: SalesforceOpportunityLineItem[];
  bdm: PointOfContact;
  lmsId: string | null;
  typeOfContract?: TypeOfContract;
  noOfExistingLicenses: number;
  sfdcAccountId: string;
  sfdcAccountName: string;
  sfdcParentAccountId?: string;
  sfdcParentAccountName?: string;
  sfdcParentOpportunityId?: string | null;
};

export type ProductOnboardingUserInput = {
  firstName: string;
  lastName: string;
  emailAddress: string;
};

export type ProductOnboardingDetailsInput = {
  organizationName: string;
  learningAdmins: ProductOnboardingUserInput[];
  emailDomains: string[];
  identityProvider?: ProductOnboardingIdentityProvider;
  ssoContact?: ProductOnboardingUserInput;
  authenticationMethod?: ProductOnboardingAuthenticationMethod;
};

export type CreateOrderItemInput = {
  quantity: number;
  productTitle: string;
  unitPrice: number;
  subTotal: number;
  classStartDate?: string;
  classEndDate?: string;
  ssoEnabled?: boolean;
  billingPeriod?: BillingPeriod;
  productOnboardingDetails: ProductOnboardingDetailsInput;
  opportunityLineItemId?: string;
  contractingRequestCreator?: ContractingRequestCreatorInput;
  subscriptionType?: TypeOfContract;
  productModality?: ProductModality;
  metadata?: Array<Record<string, string>>;
};

export type CreateOrderInput = {
  opportunityId: string;
  parentOpportunityId?: string;
  opportunityName: string;
  noOfExistingLicenses?: number;
  typeOfContract?: TypeOfContract;
  learningAccountId?: string;
  items: CreateOrderItemInput[];
  businessDevelopmentManager?: ProductOnboardingUserInput;
};

export type AddressInput = {
  name: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  stateOrProvince?: string;
  countryCode: string;
  zipCode: string;
};

export type PartialLearningAccount = {
  id: string;
  awsAccountId?: string;
  name?: string;
};

export type PartialOrganization = {
  id: string;
  awsAccountId?: string;
  name?: string;
};

export type OrderId = {
  id: string;
  learningAccount: PartialLearningAccount;
};

export type GraphQLRequestState = {
  data: any;
  loading: boolean;
  error: any;
};
